import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { useValidCustomAttributeName } from '@trustyou/shared';
import { FormControl, FormFieldLabel, TextField } from '@trustyou/ui';
import { debounce } from 'lodash';

import { common, manageDrawer } from '../../constants/messages/customAttributes';

const VALIDITY_CHECK_DEBOUNCE_TIME = 500;

export const NameController = () => {
  const { control } = useFormContext();
  const intl = useIntl();

  const { mutateAsync: checkValidName } = useValidCustomAttributeName();

  const debounceCheck = debounce(async (value: string) => {
    if (control._defaultValues.name === value) return;
    const isValid = await checkValidName(value);
    if (!isValid) {
      control.setError('name', {
        type: 'custom',
        message: intl.formatMessage(manageDrawer.nameExist),
      });
    }
  }, VALIDITY_CHECK_DEBOUNCE_TIME);

  return (
    <FormControl sx={{ maxWidth: '300px' }}>
      <FormFieldLabel required>{intl.formatMessage(common.name)}</FormFieldLabel>
      <Controller
        name="name"
        control={control}
        rules={{
          required: intl.formatMessage(manageDrawer.nameRequired),
          onChange: async (event) => {
            if (event) debounceCheck(event.target.value);
          },
        }}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            size="small"
            placeholder={intl.formatMessage(manageDrawer.namePlaceholder)}
            id="custom-attribute-name"
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            type="text"
            variant="outlined"
          />
        )}
      />
    </FormControl>
  );
};
