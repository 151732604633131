import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { faArrowLeft } from '@trustyou/fortawesome/pro-regular-svg-icons';
import {
  useAlertStore,
  useCompetitorStore,
  useEntity,
  useMembershipStore,
  useSetEntityCompetitors,
} from '@trustyou/shared';
import {
  BackdropSpinner,
  Box,
  Button,
  ContentPane,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  StyledFontAwesomeIcon as Icon,
  IconButton,
  Typography,
} from '@trustyou/ui';

import { CompetitorSelection } from '../CompetitorSelection';
import styles from './styles';

type AddCompetitorsProps = {
  entityId?: string;
  onClose: () => void;
  isDialog?: boolean;
};

const AddCompetitors = ({ entityId, onClose, isDialog }: AddCompetitorsProps) => {
  const { selectedCompetitors, setSelectedCompetitors } = useCompetitorStore();
  const { alert } = useAlertStore();
  const { membership } = useMembershipStore();
  const { data: entity } = useEntity(entityId);

  const { mutate: setEntityCompetitors, isPending: isSaving } = useSetEntityCompetitors(
    () => {
      onClose();
      alert.success(
        <FormattedMessage id="organization.competitors.added" defaultMessage="Competitors added" />
      );
    },
    () => {
      alert.genericError();
    }
  );

  const onSave = () => {
    setEntityCompetitors({
      organization_id: membership?.organizationId as string,
      entity_id: entityId as string,
      competitors: selectedCompetitors.map((row) => row.id),
    });
  };

  useEffect(() => {
    if (entity?.competitors) {
      setSelectedCompetitors(entity?.competitors);
    }
  }, [entity, setSelectedCompetitors]);

  if (isDialog) {
    return (
      <Dialog open={!!entityId} onClose={onClose} sx={styles.dialog}>
        <DialogTitle variant="h6">
          <Typography sx={styles.dialogHeader} variant="h6">
            <FormattedMessage id="organization.competitors.add" defaultMessage="Add competitors" />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={styles.body}>{entity && <CompetitorSelection entity={entity} />}</Box>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="cancel-add-competitors"
            onClick={onClose}
            variant="outlined"
            color="primary"
          >
            <FormattedMessage id="organization.action.cancel" defaultMessage="Cancel" />
          </Button>
          <Button data-testid="accept-add-competitors" variant="contained" onClick={onSave}>
            <FormattedMessage id="organization.action.save" defaultMessage="Save" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <ContentPane sx={styles.wrapper}>
      <Box sx={styles.header}>
        <IconButton data-testid="back-button" onClick={onClose}>
          <Icon icon={faArrowLeft} />
        </IconButton>
        <Typography sx={styles.headerText} variant="h6">
          <FormattedMessage id="organization.competitors.add" defaultMessage="Add competitors" />
        </Typography>
      </Box>
      <Box sx={styles.body}>{entity && <CompetitorSelection entity={entity} />}</Box>
      <Box sx={styles.footer}>
        <Button
          data-testid="cancel-add-competitors"
          onClick={onClose}
          variant="outlined"
          color="primary"
        >
          <FormattedMessage id="organization.action.cancel" defaultMessage="Cancel" />
        </Button>
        <Button data-testid="accept-add-competitors" variant="contained" onClick={onSave}>
          <FormattedMessage id="organization.action.save" defaultMessage="Save" />
        </Button>
      </Box>
      <BackdropSpinner isLoading={isSaving} />
    </ContentPane>
  );
};

export default AddCompetitors;
