import { grey } from '@mui/material/colors';
import type { IStyles } from '@trustyou/shared';

const styles: IStyles = {
  sourceCard: {
    padding: 2,
    border: `1px solid ${grey[300]}`,
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
  },
  sourceValue: {
    color: 'text.hint',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    wordBreak: 'break-word',
    overflowWrap: 'break-word',
  },
  sourceValueLink: {
    cursor: 'pointer',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    '& img': {
      height: '24px',
      width: '24px',
    },
  },
  apiChip: {
    mr: 'auto',
  },
};

export default styles;
