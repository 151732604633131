import {
  UPDATE_ENTITY_CUSTOM_ATTRIBUTES_PERMISSION,
  isTemporaryHidden,
  useCustomAttributeDefinitions,
  usePermissionStore,
} from '@trustyou/shared';

const useUpdateEntityCustomAttributes = () => {
  const { hasPermissions } = usePermissionStore();
  const isAllowed =
    !isTemporaryHidden('CXP-customers-custom-attributes') &&
    hasPermissions(UPDATE_ENTITY_CUSTOM_ATTRIBUTES_PERMISSION);
  const { data = [] } = useCustomAttributeDefinitions(isAllowed);
  return isAllowed && data.length > 0;
};

export default useUpdateEntityCustomAttributes;
