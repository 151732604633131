import { useIntl } from 'react-intl';

import {
  type Entity,
  FETCH_ENTITIES,
  SEARCH_DEBOUNCE_TIME_MS,
  getPaginationRequest,
  getSortRequest,
  searchPlaceholders,
  useEntities,
  useEntitiesGridStore,
  useGridParams,
  useManageUploadEntities,
} from '@trustyou/shared';
import { Box, EmptyEntityStateForReadOnly, SearchBar } from '@trustyou/ui';

import { ManageNoEntities } from '../../../NoData';
import EntitiesTable from '../Common/EntitiesTable';
import styles from './styles';

type AllEntitiesTableProps = {
  onAddCompetitors?: (entity: Entity) => void;
};

const AllEntitiesTable = ({ onAddCompetitors }: AllEntitiesTableProps) => {
  const gridState = useEntitiesGridStore();
  const intl = useIntl();

  const { paginationModel, sortModel, searchKey, columnVisibilityModel, setColumnVisibilityModel } =
    gridState;

  const { onPaginationModelChange, onSortModelChange, onSearch } = useGridParams(
    FETCH_ENTITIES,
    gridState
  );

  const { data, isFetching } = useEntities(
    getPaginationRequest(paginationModel),
    getSortRequest(sortModel),
    searchKey
  );

  const canUploadEntities = useManageUploadEntities();

  if (data?.pagination?.total === 0 && !searchKey)
    return canUploadEntities ? <ManageNoEntities /> : <EmptyEntityStateForReadOnly />;

  return (
    <>
      <Box sx={styles.searchBar}>
        <SearchBar
          onSearch={onSearch}
          debounceTime={SEARCH_DEBOUNCE_TIME_MS}
          placeholder={intl.formatMessage(searchPlaceholders.searchEntities)}
          id="entities"
          variant="standard"
          defaultValue={searchKey}
        />
      </Box>
      <EntitiesTable
        data={data}
        isFetching={isFetching}
        paginationModel={paginationModel}
        sortModel={sortModel}
        searchKey={searchKey}
        onAddCompetitors={onAddCompetitors}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
      />
    </>
  );
};

export default AllEntitiesTable;
