import { FormattedMessage } from 'react-intl';

import { type EntityCustomAttributes, actionMessages, commonFields } from '@trustyou/shared';
import { Button, Stack, Typography } from '@trustyou/ui';

import { AttributeValue } from './AttributeValue';

type Props = {
  attributes: EntityCustomAttributes;
  onEdit?: () => void;
};

export const EntityCustomAttributesView = ({ attributes, onEdit }: Props) => (
  <Stack gap={2}>
    <Stack direction="row" gap={1} alignItems="center">
      <Typography variant="subtitle2">
        <FormattedMessage {...commonFields.customAttributes} />
      </Typography>
      {onEdit && (
        <Button data-testid="edit-custom-attributes-values" onClick={onEdit} variant="text">
          <FormattedMessage {...actionMessages.edit} />
        </Button>
      )}
    </Stack>
    <Stack gap={3}>
      {Object.entries(attributes).map(([key, values]) => (
        <Stack key={key} direction="row">
          <Typography variant="caption" color="text.hint" width="150px">
            {key}
          </Typography>
          <Stack gap={0.5}>
            <AttributeValue values={values} />
          </Stack>
        </Stack>
      ))}
    </Stack>
  </Stack>
);
