import { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';

import { useOidc } from '@axa-fr/react-oidc';
import { AuthWrapper as AnalyticsAuthWrapper } from '@trustyou/analytics';
import { Inbox, Review } from '@trustyou/inbox';
import { NewReportWizard, ReportList, Reports, ReportsHistory } from '@trustyou/reports';
import {
  AuthPendingState,
  MEMBERSHIP_BASE_PATH,
  isTemporaryHidden,
  useAuthorizationStore,
  useChangelingStore,
  useFetchUserSettings,
  useLanguageStore,
  useManageUploadEntities,
  useMembershipStore,
  usePermissions,
} from '@trustyou/shared';
import { Dashboard as SurveyInsightsDashboard } from '@trustyou/survey-insights';
import {
  ContentLibraryImages,
  ContentLibraryList,
  ContentLibraryTexts,
  QuestionLibraryEditor,
  QuestionLibraryList,
  SurveyDetail,
  SurveyEditor,
  SurveyList,
  SurveyManager,
  ThemeDetail,
  ThemeEditor,
  ThemeList,
} from '@trustyou/survey-manager';
import { BackdropSpinner, ContentPane, NotFoundRoute, SomethingWrongRoute } from '@trustyou/ui';

import { ProtectedRoute } from './ProtectedRoute';

import {
  AccessGroupMembers,
  AccessGroups,
  AllUsers,
  Sisense as Analytics,
  BenchmarksTable,
  CreateUpdateAccessGroup,
  DevTools,
  Entities,
  EntitiesImporter,
  FakeSisenseIframe,
  GlobalSubscriptionUsage,
  GlobalSubscriptions,
  Home,
  ManageNoEntities,
  NoEntitiesReadOnly,
  Segments,
  SisenseDebugger,
  Users,
  VisitData,
  Welcome,
} from '../../pages';
import {
  ApplicationSettings,
  CustomAttributes,
  EntitySubscription,
  GeneralApplicationSettings,
  GoogleStaticConnect,
  ReviewProviderDetails,
  ReviewProvidersSettings,
  SubscriptionsSettings,
} from '../../pages/ApplicationSettings';
import {
  GeneralSettings,
  InboxProfileSettings,
  NotificationsSettings,
  UserSettings,
} from '../../pages/UserSettings';
import { useAuthorization } from '../../service/hooks/authorization/useAuthorization';
import useAlertChangeling from '../../service/hooks/changeling/useAlertChangeling';
import IsLoggedOut from '../LoadingScreen/IsLoggedOut';
import { FullScreenLayout } from '../Nav/FullScreenLayout/FullScreenLayout';
import { NavigationSidebar } from '../Nav/NavigationSidebar/NavigationSidebar';
import { TopBarLayout } from '../Nav/TopBarLayout/TopBarLayout';

/*
  This layer is responsible for authorization, changeling and dynamic index routes.
  All stuff about memberships and user organization should be processed in MembershipLayer
*/
const NavigationLayer = () => {
  const { isAuthenticated } = useOidc();
  const { isCompleted: isAuthorizationCompleted, authError, accessToken } = useAuthorization();
  const { membership, getMembershipDetails } = useMembershipStore();
  const { tokenStore, pendingState } = useAuthorizationStore();
  const navigate = useNavigate();
  const { permissions } = usePermissions(accessToken);
  const { isChangeling } = useChangelingStore();
  const { JSX: changelingAlert } = useAlertChangeling();
  const { locale, updateLocale } = useLanguageStore();
  const [searchParams] = useSearchParams();

  const targetPath = searchParams.get('target');

  const isUploadEntitiesAllowed = useManageUploadEntities();

  const {
    data: userSettings,
    isLoading: isLoadingUserSettings,
    isSuccess: isSuccessUserSettings,
  } = useFetchUserSettings(!isChangeling);
  useEffect(() => {
    if (isSuccessUserSettings) {
      const language = userSettings.settings.language;
      if (language !== 'auto' && language !== locale) {
        updateLocale(language);
      }
    }
  }, [isSuccessUserSettings, locale, updateLocale, userSettings?.settings.language]);

  const onGoBack = () => navigate('/');

  const onFinishImportEntities = () => window.location.reload();

  if (pendingState === AuthPendingState.LOGGING_OUT) {
    return <IsLoggedOut />;
  }

  if (isChangeling && !membership) {
    // tab was closed without  stop changeling
    return changelingAlert;
  }

  if (!membership) {
    console.error('missing membership in navigation layer');
    return null;
  }

  if (isAuthenticated && !isAuthorizationCompleted && authError)
    return (
      <TopBarLayout>
        <SomethingWrongRoute />
      </TopBarLayout>
    );

  // we need to wait that all logic of Authorization is up before render something
  // or we would trigger API calls without interceptors defined.
  // I will render the UI only if have permissions loaded on store.
  if (
    !isAuthenticated ||
    !isAuthorizationCompleted ||
    !tokenStore ||
    !permissions ||
    (isLoadingUserSettings && !isChangeling)
  ) {
    return <BackdropSpinner isLoading />;
  }

  const canDoInitialUpload = isUploadEntitiesAllowed;
  const isOrganizationOnboarded = getMembershipDetails()?.organization?.onboarded;
  const showOnboardedFlow = canDoInitialUpload && !isOrganizationOnboarded;

  const getEntitiesPage = () => {
    if (isOrganizationOnboarded) return <Entities />;
    if (canDoInitialUpload)
      return (
        <ContentPane>
          <ManageNoEntities />
        </ContentPane>
      );
    return (
      <ContentPane>
        <NoEntitiesReadOnly />
      </ContentPane>
    );
  };

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route element={<FullScreenLayout />}>
          <Route
            path={`${MEMBERSHIP_BASE_PATH}/reports/new-report`}
            element={<NewReportWizard />}
          />
        </Route>
        <Route element={<TopBarLayout />}>
          <Route
            path={`${MEMBERSHIP_BASE_PATH}/welcome`}
            element={
              showOnboardedFlow ? <Welcome /> : <Navigate to={`/org/${membership.id}/home`} />
            }
          />
          <Route
            path={`${MEMBERSHIP_BASE_PATH}/load-data`}
            element={
              showOnboardedFlow ? (
                <EntitiesImporter onClose={onFinishImportEntities} />
              ) : (
                <Navigate to={`/org/${membership.id}/home`} />
              )
            }
          />
          <Route
            path={`${MEMBERSHIP_BASE_PATH}/upload-subscription-entities/:chosenSubscriptionId`}
            element={<EntitiesImporter />}
          />
          <Route path={`${MEMBERSHIP_BASE_PATH}/reload-data`} element={<EntitiesImporter />} />
          <Route path="/connect-google" element={<GoogleStaticConnect />} />
          <Route path="*" element={<NotFoundRoute onGoBack={onGoBack} />} />
        </Route>
        <Route path={MEMBERSHIP_BASE_PATH} element={<NavigationSidebar />}>
          <Route
            index
            element={<Navigate to={`/org/${membership.id}/${targetPath || 'home'}`} />}
          />
          <Route path="home" element={<Home />} />
          <Route path="organization/entities" element={getEntitiesPage()}>
            <Route path="segments" element={<Segments />} />
            <Route path="visit-data" element={<VisitData />} />
          </Route>
          <Route path="organization/users" element={<Users />}>
            <Route path="members" element={<AllUsers />} />
            <Route path="access-groups" element={<AccessGroups />} />
            <Route path="access-groups/:accessGroupId/members" element={<AccessGroupMembers />} />
            <Route
              path="access-groups/:accessGroupId/update"
              element={<CreateUpdateAccessGroup />}
            />
            <Route path="access-groups/create" element={<CreateUpdateAccessGroup />} />
          </Route>
          <Route
            path="analytics"
            element={
              <AnalyticsAuthWrapper>
                <Analytics isChangeling={isChangeling} />
              </AnalyticsAuthWrapper>
            }
          />
          <Route
            path="sisense-debugger"
            element={
              <AnalyticsAuthWrapper>
                <SisenseDebugger isChangeling={isChangeling} />
              </AnalyticsAuthWrapper>
            }
          />
          {!isTemporaryHidden('CXP-poc-reviews-dialog') && (
            <Route path="poc" element={<FakeSisenseIframe />} />
          )}
          <Route path="dev-tools" element={<DevTools />} />
          <Route path="inbox" element={<Inbox />}>
            <Route path=":reviewId" element={<Review />} />
          </Route>
          <Route path="survey-manager" element={<SurveyManager isChangeling={isChangeling} />}>
            <Route path="survey">
              <Route path="overview" element={<SurveyList />} />
              <Route path="detail/:surveyId" element={<SurveyDetail />} />
              <Route path="editor" element={<SurveyEditor />} />
            </Route>
            <Route path="question">
              <Route path="overview" element={<QuestionLibraryList />} />
              <Route path="editor" element={<QuestionLibraryEditor />} />
            </Route>
            <Route path="content-library">
              <Route path="overview" element={<ContentLibraryList />} />
              <Route path="texts" element={<ContentLibraryTexts />} />
              <Route path="images" element={<ContentLibraryImages />} />
            </Route>
            <Route path="theme">
              <Route path="overview" element={<ThemeList />} />
              <Route path="detail/:themeId" element={<ThemeDetail />} />
              <Route path="editor" element={<ThemeEditor />} />
            </Route>
          </Route>
          {!isTemporaryHidden('CXP-customers-survey-insights') && (
            <Route
              path="survey-insights"
              element={
                <AnalyticsAuthWrapper>
                  <SurveyInsightsDashboard isChangeling={isChangeling} />
                </AnalyticsAuthWrapper>
              }
            />
          )}
          {!isTemporaryHidden('CXP-customers-reports') && (
            <Route path="reports" element={<Reports />}>
              <Route path="list" element={<ReportList />} />
              <Route path="history" element={<ReportsHistory />} />
            </Route>
          )}
          <Route path="settings/application" element={<ApplicationSettings />}>
            <Route path="general" element={<GeneralApplicationSettings />} />
            {/* <Route path="dashboard" element={<DashboardSettings />} /> */}
            {/* <Route path="inbox" element={<InboxSettings />} /> */}
            <Route path="subscriptions" element={<SubscriptionsSettings />} />
            <Route
              path="subscriptions/:chosenSubscriptionId/:usage"
              element={<EntitySubscription />}
            />
            <Route path="review-providers" element={<ReviewProvidersSettings />} />
            <Route path="review-providers/:reviewProviderId" element={<ReviewProviderDetails />} />
            {!isTemporaryHidden('CXP-customers-custom-attributes') && (
              <Route path="custom-attributes" element={<CustomAttributes />} />
            )}
          </Route>
          <Route path="settings/user" element={<UserSettings />}>
            <Route path="general" element={<GeneralSettings />} />
            <Route path="notifications" element={<NotificationsSettings />} />
            <Route path="inbox" element={<InboxProfileSettings />} />
            {/* <Route path="dashboard" element={<DashboardProfileSettings />} /> */}
          </Route>
          {!isTemporaryHidden('CXP-customers-subscription') && (
            <>
              <Route path="subscriptions" element={<GlobalSubscriptions />} />
              <Route
                path="subscriptions/:chosenSubscriptionId/:usage"
                element={<GlobalSubscriptionUsage />}
              />
            </>
          )}
          {!isTemporaryHidden('CXP-customers-benchmark') && (
            <Route path="benchmarks" element={<BenchmarksTable />} />
          )}
        </Route>
      </Route>
    </Routes>
  );
};

export default NavigationLayer;
