import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import type {
  GridColumnVisibilityModel,
  GridPaginationModel,
  GridRowParams,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import {
  type EntitiesResponse,
  type Entity,
  useAlertStore,
  useChangelingStore,
  useDeleteEntity,
  useResponsive,
} from '@trustyou/shared';
import { BackdropSpinner, DataGrid, SecondaryDrawer, SectorAvatarIcon, Stack } from '@trustyou/ui';

import EntityDeleteModal from './EntityDeleteModal';
import useEntitiesTableColumns from './useEntitiesTableColumns';
import useHandleExportEntities from './useHandleExportEntities';

import { EntityDetailView } from '../../../../components';
import { useShowCompetitors } from '../../../../hooks';
import styles from './styles';

type EntitiesTableProps = {
  data?: EntitiesResponse;
  isFetching: boolean;
  paginationModel: GridPaginationModel;
  sortModel: GridSortModel;
  searchKey?: string;
  groupId?: string;
  columnVisibilityModel: GridColumnVisibilityModel;
  setColumnVisibilityModel?: (model: GridColumnVisibilityModel) => void;
  onSortModelChange: (model: GridSortModel) => void;
  onPaginationModelChange: (model: GridPaginationModel) => void;
  onAddCompetitors?: (entity: Entity) => void;
  actionsDisabled?: boolean;
  exportDisabled?: boolean;
  refetchSubscription?: boolean;
};

const EntitiesTable = ({
  onAddCompetitors,
  data,
  isFetching,
  paginationModel,
  sortModel,
  searchKey,
  groupId,
  onSortModelChange,
  onPaginationModelChange,
  actionsDisabled,
  columnVisibilityModel,
  setColumnVisibilityModel,
  exportDisabled,
  refetchSubscription,
}: EntitiesTableProps) => {
  const { isMobileRange } = useResponsive();
  const [deletableEntity, setDeletableEntity] = useState<Entity>();
  const [selectedEntity, setSelectedEntity] = useState<Entity>();
  const { alert } = useAlertStore();
  const { isChangeling } = useChangelingStore();

  const columns = useEntitiesTableColumns({
    handleAddCompetitors: onAddCompetitors,
    handleDelete: setDeletableEntity,
    actionsDisabled,
  });

  const { mutate: handleDeleteEntity, isPending: isDeleting } = useDeleteEntity(
    () => {
      alert.info(
        <FormattedMessage
          id="organization.entityDeletedAlert"
          defaultMessage="{entity} is deleted"
          values={{ entity: deletableEntity?.name }}
        />
      );
    },
    () => {
      alert.genericError();
    },
    () => setDeletableEntity(undefined),
    refetchSubscription
  );

  const onDelete = () => {
    handleDeleteEntity(deletableEntity?.id as string);
  };

  const handleExport = useHandleExportEntities(sortModel, searchKey, groupId);

  const handleRowClick = (params: GridRowParams) => {
    setSelectedEntity(params.row);
  };

  const allowCompetitors = useShowCompetitors() && !!onAddCompetitors;

  // TODO: Remove this and use default id as unique row id when BE returns unique entities.
  const rows = data?.data.map((row, rowId) => ({ ...row, rowId })) || [];

  return (
    <>
      <DataGrid
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
        paginationMode="server"
        onPaginationModelChange={onPaginationModelChange}
        rowCount={data?.pagination.total}
        sx={styles.table}
        rowHeight={64}
        columns={columns}
        rows={rows}
        getRowId={(row) => row.rowId}
        onRowClick={handleRowClick}
        loading={isFetching}
        onCsvExport={!exportDisabled ? handleExport?.onExport : undefined}
        initialState={{
          pagination: { paginationModel },
        }}
        disableColumnMenu={false}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={setColumnVisibilityModel}
        enableToolbarColumnsButton={!!setColumnVisibilityModel}
        pinnedColumns={!isMobileRange ? { left: ['name'], right: ['action'] } : undefined}
        autoHeight={false}
        containerStyle={styles.tableContainer}
      />
      <BackdropSpinner isLoading={handleExport?.isLoading || isDeleting} />
      <EntityDeleteModal
        onClose={() => {
          setDeletableEntity(undefined);
        }}
        onDelete={onDelete}
        entity={deletableEntity}
      />
      <SecondaryDrawer
        header={
          selectedEntity && (
            <Stack direction="row" gap={1} alignItems="center">
              <SectorAvatarIcon sector={selectedEntity.sector} />
              {selectedEntity.name}
            </Stack>
          )
        }
        open={!!selectedEntity}
        onClose={() => setSelectedEntity(undefined)}
        changelingMode={isChangeling}
      >
        {selectedEntity && (
          <EntityDetailView
            entityId={selectedEntity.id}
            showCompetitors={allowCompetitors}
            onAddCompetitors={
              allowCompetitors && !isMobileRange
                ? () => onAddCompetitors(selectedEntity)
                : undefined
            }
          />
        )}
      </SecondaryDrawer>
    </>
  );
};

export default EntitiesTable;
